.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hamburger{
  display: none;
  width: 2rem;
  height: 2rem;


}

@media (max-width: 767px){
  .hamburger{
    display: fixed;
    padding-top: 10px;
    padding-left: 10px;
    z-index: 10;
  }
}

.dropdown{
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--placeholder-color) ;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: var(--placeholder-color);
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:  var(--placeholder-color);
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  var(--placeholder-color);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color:  var(--placeholder-color);
}

.body-dark{
  --placeholder-color: #212529;
}

.body-light{
  --placeholder-color: #6c757d;
}